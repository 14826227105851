import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import WelcomeBanner from "../components/WelcomeBanners"
import ContactForm from "../components/ContactUs/ContactForm"
import LocationMap from "../components/ContactUs/LocationMap"

import ContactImg from "../images/contact_img.svg"

const ContactUs = () => {
  return (
    <Layout>
      <SEO title="ContactUs" />
      <WelcomeBanner bannerTitle={"Contact Us"} bannerImage={ContactImg} />

      <ContactForm></ContactForm>
      <LocationMap></LocationMap>
    </Layout>
  )
}

export default ContactUs
