import React,{useState} from "react"
import PropTypes from "prop-types"
import { store } from 'react-notifications-component';

import { IoIosCall, IoIosPin, IoIosMail } from "react-icons/io"

const ContactForm = () => {
  const [formData, setFormData] = useState(null);

  const handleChange = e => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  }
  const encode = data => {
    return Object.keys(data)
      .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
      .join("&");
  }
  const handleSubmit = e => {
    e.preventDefault();
    const form = e.target;
    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: encode({
        "form-name": form.getAttribute("name"),
        ...formData,
      }),
    })
      .then(() => {
        setFormData(null)
        store.addNotification({
          title: "Contactus",
          message: "Data added successfully",
          type: "success",
          insert: "bottom",
          container: "bottom-center",
          animationIn: ["animated", "fadeIn"],
          animationOut: ["animated", "fadeOut"],
          dismiss: {
            duration: 5000,
            onScreen: true,
          },
        })
      })
      .catch(error => console.log("error>>>", error));
  }
  return (
    <div>
      <div className="ContactForm">
        <div className="container">
          <div className="row">
            <div className="col-lg-8 col-md-12">
              <h4 className="title">Ask your Query?</h4>
              <form 
              name="contactus"
              method="POST"
              data-netlify="true"
              data-netlify-honeypot="bot-field"
              onSubmit={handleSubmit}>
                <input type="hidden" name="form-name" value="contact" />
                <div className="row">
                  <div className="form-group col-md-6">
                    <input
                      type="text"
                      name="firstName"
                      className="form-control"
                      placeholder="First Name"
                      onChange={handleChange}
                      value={formData?formData.firstName:''}
                    />
                  </div>
                  <div className="form-group col-md-6">
                    <input
                      type="text"
                      name="lastName"
                      className="form-control"
                      placeholder="Last Name"
                      onChange={handleChange}
                      value={formData?formData.lastName:''}
                    />
                  </div>
                  <div className="form-group col-md-6">
                    <input
                      type="email"
                      name="email"
                      className="form-control"
                      placeholder="Email"
                      onChange={handleChange}
                      value={formData?formData.email:''}
                    />
                  </div>
                  <div className="form-group col-md-6">
                    <input
                      type="number"
                      name="mobile"
                      className="form-control"
                      placeholder="Mobile"
                      onChange={handleChange}
                      value={formData?formData.mobile:''}
                    />
                  </div>
                </div>
                <div className="form-group">
                  <textarea
                    type="text"
                    name="query"
                    className="form-control"
                    placeholder="Query"
                    onChange={handleChange}
                    value={formData?formData.query:''}
                  />
                </div>
                <div className="text-right">
                  <button className="btn btn-theme btn-success">
                    Get Started
                  </button>
                </div>
              </form>
            </div>
            <div className="col-md-12 col-lg-4 col-xl-3 offset-xl-1">
              <h4 className="title">Get in Touch With Us</h4>

              <ul className="getIn_Touch">
                <li>
                  <IoIosCall className="contactIcons success"></IoIosCall>
                  <div className="contactDetails">
                    <h4>Call Us</h4>
                    <p>Phone: +262 695 2601</p>
                  </div>
                </li>
                <li>
                  <IoIosPin className="contactIcons primary"></IoIosPin>
                  <div className="contactDetails">
                    <h4>Call Us</h4>
                    <p>Phone: +262 695 2601</p>
                  </div>
                </li>
                <li>
                  <IoIosMail className="contactIcons warning"></IoIosMail>
                  <div className="contactDetails">
                    <h4>Call Us</h4>
                    <p>Phone: +262 695 2601</p>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

ContactForm.propTypes = {
  ContactForm: PropTypes.object,
}

export default ContactForm
