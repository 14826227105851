import React from "react"
import PropTypes from "prop-types"

const LocationMap = () => {
  return (
    <div>
      <div className="LocationMap">
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3680.3494513744868!2d75.88391945112829!3d22.715249233369516!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3962fd0bbd8c1735%3A0xf4b57bf506c3acbb!2s6DegreesIT%20Pvt.%20Ltd.!5e0!3m2!1sen!2sin!4v1582894047368!5m2!1sen!2sin"
          width="100%"
          height="600"
          frameborder="0"
          style={{ border: 0 }}
          allowfullscreen=""
        ></iframe>
      </div>
    </div>
  )
}

LocationMap.propTypes = {
  LocationMap: PropTypes.object,
}

export default LocationMap
